import mapStyles from '../map-styles';

let map = null;
function addMarker($marker, map) {
  const pin = $marker.attr('data-pin');
  const type = $marker.attr('data-type');
  const latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );
  const marker = new google.maps.Marker({
    position: latlng,
    map,
    icon: pin,
    type: type,
  });

  map.markers.push(marker);

  if ($marker.html()) {
    const infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
    });

    google.maps.event.addListener(marker, 'click', () => {
      infowindow.open(map, marker);
    });
  }
}
const mapfilter = document.querySelectorAll('.map-filter');
if (mapfilter) {
  mapfilter.forEach(function (filter) {
    filter.addEventListener('change', (e) => {
      const id = e.target.value;
      for (var i = 0; i < map.markers.length; i++) {
        const marker = map.markers[i];

        if (document.getElementById(id).checked) {
          if (marker.type == id) {
            marker.setVisible(true);
          }
        } else {
          if (marker.type == id) {
            marker.setVisible(false);
          }
        }
      }
    });
  });
}
function centerMap(map) {
  const bounds = new google.maps.LatLngBounds();
  $.each(map.markers, (i, marker) => {
    const latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  if (map.markers.length === 1) {
    map.setCenter(bounds.getCenter());
    map.setZoom(10);
  } else {
    map.fitBounds(bounds);
  }
}

function newMap($el) {
  const $markers = $el.find('.marker');
  const args = {
    zoom: 12,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    styles: mapStyles,
    zoomControl: true,
  };
  map = new google.maps.Map($el[0], args);

  map.markers = [];
  $markers.each(function () {
    addMarker($(this), map);
  });
  centerMap(map);

  return map;
}
export default {
  init() {
    // JavaScript to be fired on all pages
    if ($('.single-map').length > 0) {
      map = newMap($('.single-map'));
    }
    /* SEARCH BOX SCRIPT */
    jQuery(document).ready(function () {
      jQuery('#hide').click(function () {
        jQuery('.search-overlay').fadeOut(200);
      });

      jQuery('#show').click(function () {
        jQuery('.search-overlay').fadeIn(200);
      });
    });

    jQuery(document).mouseup(function (e) {
      const container = jQuery('.search-overlay');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.fadeOut(200);
      }
    });
  },
  // END SEARCH BOXSCRIPT
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
