import 'slick-carousel';

export default {
  init() {
    // JavaScript to be fired on the home page
    // HORIZONTAL SCROLLER
  var scrolled=0;
  $(document).ready(function(){	
      $("#downClick").on("click" ,function(){
                  scrolled=scrolled+300;        
                  $(".latest-news").animate({
                          scrollTop:  scrolled
                  });
              });
      $("#upClick").on("click" ,function(){
                  scrolled=scrolled-300; 
                  $(".latest-news").animate({
                          scrollTop:  scrolled
                  });
              });
  $(".clearValue").on("click" ,function(){
                  scrolled=0;
          });
  });
  // END
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};


