// Import dependencies
import 'bootstrap';

// Import styles
import '../styles/main.scss';

// Import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
document.addEventListener('DOMContentLoaded', () => {
  routes.loadEvents();
});

// Simple check to see if jQuery is loaded
window.onload = function() {
  return window.jQuery
    ? console.log('jQuery is loaded 👍') // eslint-disable-line no-console
    : console.log('jQuery did not load 👎'); // eslint-disable-line no-console
};
